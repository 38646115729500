<template>
  <div class="userAgreement">
    <iframe id="inlineFrameExample" width="100%" height="100%" :src="src">
    </iframe>
  </div>
</template>
  
  <script>
export default {
  name: 'userAgreement',
  data () {
    return {
      src: ''
    };
  },
  mounted () {
    this.src = process.env.BASE_URL + 'yszc.html'
  },
  methods: {
  }
};
  </script>
  <style lang="less" scoped>
.userAgreement {
  overflow: hidden;
}
</style>
  