<template>
  <div class="ticket"></div>
</template>
  
  <script>
import { ticket, isLogin } from 'src/services/login';
export default {
  name: 'ticket',
  data () {
    return {
      src: ''
    };
  },
  mounted () {
    // 判断是否是跳转过来的
    if (window.location.hash.indexOf('from=') !== -1) {
      const from = window.location.hash.split('from=')[1]
      if (from) {
        // 其他页面跳转
        this.appReferer = document.referrer || window.location.href
        this.from = from
      } else {
        // 本页面登录
        this.appReferer = window.location.href
        console.log(this.appReferer)
      }
    } else {
      this.appReferer = window.location.href
      console.log(this.appReferer)
    }
    // 判断是否已经登录过
    this.judgingLogin()
  },
  methods: {
    judgingLogin () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          if (res.data.data) {
            this.open()
          } else {
            // 判断是必须登录的页面还是不需要登录的页面 jl 1必须登录 2无需登录
            if (this.from.indexOf('&jl') !== -1) {
              this.$router.push({
                path: '/login',
                query: {
                  appReferer: this.appReferer,
                  from: this.from.split('&jl')[0]
                }
              })
            } else {
              let hash = this.appReferer.split('/#/')
              window.location.href = hash[0] + "#/login?from=" + this.from + '&ticket=404';
            }
          }
        }
      })
    },
    // 替换子应用ticket
    async open () {
      // 获取子应用Ticket
      let ticketVal = await ticket({ client: 'web', appReferer: this.appReferer })
      if (this.appReferer) {
        console.log(this.appReferer);
        // 如果this.appReferer === 当前页面地址就跳转用户端主页
        if (this.appReferer !== window.location.href) {
          let hash = this.appReferer.split('/#/')
          window.location.href = hash[0] + "#/login?from=" + this.from + '&ticket=' + ticketVal.data.data.ticket;
        } else {
          // 跳转用户端pc页面
          window.location.href = window.userPC + "#/login?from=#/plant" + '&ticket=' + ticketVal.data.data.ticket;
        }
      }
    },
  }
};
  </script>
  <style lang="less" scoped>
.ticket {
  overflow: hidden;
}
</style>
  